





























































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_SEARCH, GET_BY_ID } from '../store';
import ConservatorshipSelect from '@/modules/conservatorship/components/ConservatorshipSelect.vue';
import CompensationTable from '../components/CompensationTable.vue';
import { ApiResponse } from '@/components/types';
import { Compensation, SearchParams } from '@/modules/compensation/types';
import { routeToLocation } from '@/router';

const CompensationStore = namespace('compensation');

@Component({
  components: {
    ConservatorshipSelect,
    CompensationTable
  }
})
export default class Search extends Vue {
  @CompensationStore.Action(FETCH_SEARCH) fetch!: (search: SearchParams) => Promise<ApiResponse<Compensation[]>>;
  @CompensationStore.Getter(GET_BY_ID) getById!: (id: string) => Compensation | undefined;

  loading: boolean = false;
  rowsPerPage: number = 10;
  results: Compensation[] = [];
  error: any = null;
  searched: boolean = false;

  get items() {
    return this.results.map((compensation) => this.getById(compensation.id) || compensation);
  }

  get conservatorship(): string {
    return this.$route.query['conservatorship'] as string;
  }

  set conservatorship(conservatorship: string) {
    if (!conservatorship) {
      const { conservatorship, ...query } = this.$route.query;
      this.$router.push(routeToLocation({ ...this.$route, query: { ...query } }));
      return;
    }

    this.$router.push(routeToLocation({ ...this.$route, query: { ...this.$route.query, conservatorship } }));
  }

  get invoice(): string {
    return this.$route.query['invoice'] as string;
  }

  set invoice(invoice) {
    if (!invoice) {
      const { invoice, ...query } = this.$route.query;
      this.$router.push(routeToLocation({ ...this.$route, query: { ...query } }));
      return;
    }

    this.$router.push(routeToLocation({ ...this.$route, query: { ...this.$route.query, invoice } }));
  }

  reset() {
    this.search = '';
    this.invoice = '';
    this.conservatorship = '';

    this.loading = false;
    this.results = [];
  }

  async searchCompensation() {
    this.error = null;
    this.searched = false;

    this.loading = true;
    const { error, content } = await this.fetch({ conservatorship: this.conservatorship, invoice: this.invoice });
    this.loading = false;

    if (error) {
      this.error = error;

      return;
    }

    this.searched = true;

    if (!content) return;

    this.results = content;
  }

  created() {
    if (!this.invoice && !this.conservatorship) return;

    return this.searchCompensation();
  }
}
